<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Spesialrom</h2>
			<p>
				I tillegg til å ta hånd om dine mer dagligdagse rom som kjøkken og bad, hjelper Ellingsen Byggeservice deg også med mer tilpassede spesialrom som badstu eller hjemmekino. 
                Kanskje er det også andre grunner til at du må bygge rom litt uten om det vanlige - som eksempelvis at det stilles spesialkrav til ventilasjon, tetthet eller isolasjon? Dette tar vi hånd om!
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 800px;
	max-width: 70%;
	padding-top: 2%;
}
</style>
